import React, { useRef, useState } from 'react';
import { navigate } from 'gatsby';
import HoverImage from '../hoverImage';
import killPageDefaultTransition from '../../helpers/killPageDefaultTransition';

import './styles.scss';

const WorksList = ({ data, currentProject = null }) => {

	const titleEls = useRef([]);
	const [currentTitle, setCurrentTitle] = useState(null);
	const [hoverImageToShow, setHoverImageToShow] = useState(null);
	const [isClicked, setIsClicked] = useState(false);

	const handleTitleEnter = e => {
		// Set current title
		const currentTitle = e.target.closest('.WorksList__Titles');
		setCurrentTitle(currentTitle)
		// Reduce opacity for other links
		currentTitle.classList.add('current');
		titleEls.current.map(title => title.classList.add('title-hover'))
		// Set hover images to show
		const newImageIndex = currentTitle.getAttribute('data-hover-image-index');
		setHoverImageToShow(data[newImageIndex].node.hover_imageSharp);
	}

	const handleTitleLeave = e => {
		if (isClicked) return;
		// Reset opacity for all links
		currentTitle.classList.remove('current');
		titleEls.current.map(title => title.classList.remove('title-hover'))
		// Hide hover image
		setHoverImageToShow(null);
		// Reset current title
		setCurrentTitle(null)
	}

	const handleClick = e => {
		if (localStorage.getItem('is-touch') === 'true' || window.innerWidth <= 1200) {
			const slug = e.target.closest('.WorksList__Titles').getAttribute('data-work-slug');
			navigate(`/${slug}`);
			return false;
		}
		setIsClicked(true);
		window.dispatchEvent(window.stopScrollEvent);
		document.body.classList.add('title-click');
		currentTitle.style.padding = `0`;
		// const scale = window.innerWidth >= 1920 ? 1.9886363636 : 1.8055555556;
		const scale = 1.8055555556;
		const distRatio = window.innerWidth >= 1920 ? 0.2776041667 : 0.3055555556;
		// const distRatio = 0.3055555556;
		const rect = currentTitle.getBoundingClientRect();
		const distY = window.innerWidth * distRatio - rect.top;
		currentTitle.style.transform = `translate3d(0, ${distY}px, 0) scale(${scale})`;
		setTimeout(() => {
			killPageDefaultTransition(true);
			const slug = currentTitle.getAttribute('data-work-slug');
			navigate(`/${slug}`);
			window.addEventListener('nextPageLoadedEvent', () => {
				setTimeout(() => {
					document.body.classList.remove('title-click');
				}, 1200)
			})
		}, 1200)
	}

	const works = data.map((work, index) => {
		if (work.node._meta.uid === currentProject) return;
		return (
			<h5
				className="WorksList__Titles"
				key={`title-${index}`}
				ref={ref => titleEls.current[index] = ref}
				onMouseEnter={handleTitleEnter}
				onMouseLeave={handleTitleLeave}
				data-hover-image-index={index}
				onClick={handleClick}
				data-work-slug={work.node._meta.uid}
				data-cursor-scale
			>
				{work.node.title[0].text}
				{work.node.note && <span className="note"> {work.node.note[0].text} </span>}
			</h5>
		)
	})

	return (
		<section className="WorksList">
			{works}
			<div
				className="WorksList__HoverImageContainer"
				data-scroll
				data-scroll-sticky
				data-scroll-target="#fixed-elements-target"
			>
				<HoverImage
					imageToShow={hoverImageToShow}
					className="WorksList__HoverImage"
				/>
			</div>
		</section>
	)

}

export default WorksList;