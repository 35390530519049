import React from "react"
import SEO from "../../components/seo"
import { graphql } from "gatsby"
import { RichText } from 'prismic-reactjs';
import Img from 'gatsby-image';
import Scroll from '../../components/scroll';
import TextBlock from '../../components/textBlock';
import WorksList from '../../components/worksList'
import Footer from '../../components/footer'
import SplitText from '../../components/splitText';

import './styles.scss';

const Project = ({ data: { prismic } }) => {
	const data = prismic.project;
	const dataWorks = prismic.allProjects.edges;

	const sections = data.body ? data.body.map((section, index) => {

		if (section.type === 'two_images') {
			return (
				<div className="Project__Section Project__TwoImages" key={`twoimages-${index}`}>
					<div className="Project__TwoImagesImageContainer" data-scroll>
						<Img className="Project__TwoImagesImage" fluid={section.primary.image_leftSharp.childImageSharp.fluid} />
						<span className="Project__Caption">{section.primary.caption_left && section.primary.caption_left[0].text}</span>
					</div>
					<div className="Project__TwoImagesImageContainer" data-scroll>
						<Img className="Project__TwoImagesImage" fluid={section.primary.image_rightSharp.childImageSharp.fluid} />
						<span className="Project__Caption">{section.primary.caption_right && section.primary.caption_right[0].text}</span>
					</div>
				</div>
			)
		}
		else if (section.type === 'centered_textblock') {
			return (
				<div className="Project__Section Project__CenteredTextBlock" key={`textblock-${index}`} data-scroll>
					<TextBlock
						title={section.primary.title1}
						paragraphs={section.primary.paragraphs}
            className="Project__CenteredTextBlockBlock"
            isSplitAppear
					/>
				</div>
			)
		}
		else if (section.type === 'full_width_image') {
			return (
				<div className="Project__Section Project__FullWidthImage" key={`fullwidthimage-${index}`} data-scroll>
					<Img className="Project__FullWidthImageImage" fluid={section.primary.imageSharp.childImageSharp.fluid} />
					<span className="Project__Caption">{section.primary.caption && section.primary.caption[0].text}</span>
				</div>
			)
		}
		else if (section.type === 'image_and_video') {
			return (
				<div className="Project__Section Project__ImageVideo" key={`imagevideo-${index}`}>
					<div className="Project__ImageVideoContainer" data-scroll>
						<Img className="Project__ImageVideoImage" fluid={section.primary.imageSharp.childImageSharp.fluid} />
						<span className="Project__Caption">{section.primary.caption_image && section.primary.caption_image[0].text}</span>
					</div>
					<div className="Project__ImageVideoContainer" data-scroll>
						{section.primary.video &&
							<video className="Project__ImageVideoVideo" src={section.primary.video.url} muted autoPlay loop></video>
						}
						<span className="Project__Caption">{section.primary.caption_video && section.primary.caption_video[0].text}</span>
					</div>
				</div>
			)
		}
		else if (section.type === 'long_image_and_text') {
			return (
				<div
					className="Project__Section Project__LongImageText"
					key={`longimagetext-${index}`}
					id="longImageSticky"
					data-scroll
				>
					<div className="Project__LongImageTextImageContainer" data-scroll>
						<Img className="Project__LongImageTextImage" fluid={section.primary.imageSharp.childImageSharp.fluid} />
					</div>
					<div
						className="Project__LongImageTextTextContainer"
						data-scroll
						data-scroll-sticky
						data-scroll-target="#longImageSticky"
					>
						<TextBlock
							paragraphs={section.primary.text}
							className="Project__LongImageTextText"
							isSplitAppear
						/>
					</div>
					<div className="Project__LongImageTextTextContainer Project__LongImageTextTextContainer--Mobile">
						<TextBlock
							paragraphs={section.primary.text}
							className="Project__LongImageTextText"
							isSplitAppear
						/>
					</div>
				</div>
			)
		}
		else if (section.type === 'full_width_video') {
			return (
				<div className="Project__Section Project__FullWidthVideo" key={`imagevideo-${index}`} data-scroll>
					<video className="Project__FullWidthVideoVideo" src={section.primary.video.url} muted autoPlay loop></video>
					<span className="Project__Caption">{section.primary.caption && section.primary.caption[0].text}</span>
				</div>
			)
		}

	}) : [];

  return (
		<div>
			<React.Fragment>
				<SEO
					title={data.title[0].text}
				/>
				<Scroll onScroll={() => {}}>
					<section className="Project">
						{data.title &&
							<h1 className="Project__Title" data-scroll data-scroll-position="top" data-scroll-speed="1.4"> {data.title[0].text} </h1>
						}
						{data.main_imageSharp &&
							<div className="Project__MainImageContainer" data-scroll>
								<Img className="Project__MainImage" fluid={data.main_imageSharp.childImageSharp.fluid} />
							</div>
						}
						<div className="Project__Infos" data-scroll>
							<div className="Project__InfosLeft">
								{data.location &&
									<div className="Project__InfosBlock">
										<h3 className="Project__InfosTitle" data-scroll>Location</h3>
										<div className="Project__InfosText" data-scroll>
                      <SplitText splitBy="lines">
											  <RichText render={data.location} />
                      </SplitText>
										</div>
									</div>
								}
								{data.deliverables &&
									<div className="Project__InfosBlock">
										<h3 className="Project__InfosTitle" data-scroll>Deliverables</h3>
										<div className="Project__InfosText" data-scroll>
                      <SplitText splitBy="lines">
											  <RichText render={data.deliverables} />
                      </SplitText>
										</div>
									</div>
								}
							</div>
							<div className="Project__InfosRight">
								{data.description_headline &&
									<h3 className="Project__InfosTitle" data-scroll> <RichText render={data.description_headline} /> </h3>
								}
								{data.description_text &&
									<div className="Project__InfosText" data-scroll>
                    <SplitText splitBy="lines">
										  <RichText render={data.description_text} />
                    </SplitText>
									</div>
								}
							</div>
						</div>
						<div className="Project__Sections">
							{sections}
						</div>
					</section>
					<WorksList data={dataWorks} currentProject={data._meta.uid} />
					<Footer />
				</Scroll>
			</React.Fragment>
		</div>
  )
}

export default Project

export const pageQuery = graphql`
  query ProjectBySlug($uid: String!) {
		prismic {
			allProjects(sortBy:meta_firstPublicationDate_DESC) {
				edges {
					node {
						_meta {
							uid
						}
						title
						note
						hover_image
          	hover_imageSharp {
							childImageSharp {
								fluid(maxWidth: 2000) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
			project(lang: "en-us", uid: $uid) {
				_meta {
					uid
				}
				title
				note
				deliverables
				description_headline
				description_text
				location
				main_image
				main_imageSharp {
					childImageSharp {
						fluid(maxWidth: 2000) {
							...GatsbyImageSharpFluid
						}
					}
				}
				body {
					... on PRISMIC_ProjectBodyFull_width_image {
						type
						primary {
							image
							imageSharp {
								childImageSharp {
									fluid(maxWidth: 2000) {
										...GatsbyImageSharpFluid
									}
								}
							}
							caption
						}
					}
					... on PRISMIC_ProjectBodyTwo_images {
						type
						primary {
							image_left
							image_right
							image_leftSharp {
								childImageSharp {
									fluid(maxWidth: 2000) {
										...GatsbyImageSharpFluid
									}
								}
							}
							image_rightSharp {
								childImageSharp {
									fluid(maxWidth: 2000) {
										...GatsbyImageSharpFluid
									}
								}
							}
							caption_left
							caption_right
						}
					}
					... on PRISMIC_ProjectBodyCentered_textblock {
						type
						primary {
							paragraphs
							title1
						}
					}
					... on PRISMIC_ProjectBodyImage_and_video {
						type
						primary {
							image
							video {
								... on PRISMIC__FileLink {
									url
								}
							}
							imageSharp {
								childImageSharp {
									fluid(maxWidth: 2000) {
										...GatsbyImageSharpFluid
									}
								}
							}
							caption_image
							caption_video
						}
					}
					... on PRISMIC_ProjectBodyLong_image_and_text {
						type
						primary {
							image
							text
							imageSharp {
								childImageSharp {
									fluid(maxWidth: 2000) {
										...GatsbyImageSharpFluid
									}
								}
							}
						}
					}
					... on PRISMIC_ProjectBodyFull_width_video {
						type
						primary {
							video {
								... on PRISMIC__FileLink {
									_linkType
									url
								}
							}
							caption
						}
					}
				}
			}
		}
  }
`